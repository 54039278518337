import React, { Component } from "react";
import { Button, Card, CardBody, CardHeader } from "reactstrap";
// import "bootstrap/dist/css/bootstrap.min.css";

class CategoryList extends Component {
  render() {
    const categories = [
      { name: "Foodstuff", totalItems: 50 },
      { name: "Category 2", totalItems: 50 },
      { name: "Category 3", totalItems: 20 },
      { name: "Category 4", totalItems: 30 },
      { name: "Category 5", totalItems: 10 },
      { name: "Category 6", totalItems: 5 },
    ];

    const currentPath = window.location.href;
    const redirectPathForProduct = currentPath.split('/').slice(0, -1).join('/') + '/product-list?category=car';
    const redirectPathForAddList = currentPath.split('/').slice(0, -1).join('/') + '/create-product-list';

    return (
      <div className='w-100 px-0 py-2'>
        <Card>
          <CardHeader className='c-header'>Product Database</CardHeader>
          <CardBody>
            <div className="d-flex justify-content-between w-100 my-3">
              <h4 className="ml-3">Product List</h4>
              <div className='ms-auto text-end mr-3'>
                <Button style={{ whiteSpace: "nowrap" }} color={"primary"} onClick={() => window.location.href = redirectPathForAddList}>+ Add Products List</Button>
              </div>
            </div>
            <table className='table table-bordered'>
              <thead>
                <tr>
                  <th>Category List</th>
                  <th>Total Items</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {categories.map((category) => (
                  <tr key={"category-" + category}>
                    <td>{category.name}</td>
                    <td>{category.totalItems}</td>
                    <td>
                      <button onClick={() => window.location.href = redirectPathForProduct} className='py-1 px-2 bg-primary rounded mr-2'>
                        <i className="fa fa-eye" style={{ fontSize: "20px", color: "white" }}></i>
                      </button>
                      <button className='py-1 px-2 bg-primary rounded mr-2'>
                        <i className="fa fa-edit" style={{ fontSize: "20px", color: "white", paddingTop: "2px" }}></i>
                      </button>
                      <button className='py-1 px-2 bg-danger rounded'>
                        <i className="fa fa-trash" style={{ fontSize: "20px", color: "white" }}></i>
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </CardBody>
        </Card>
      </div>
    );
  }
}

export default CategoryList;
