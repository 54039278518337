import Index from "./Dashboard/HomePage/HomePage";
import CreateShipment from "./Dashboard/CreateShipment/CreateShipment";
import UploadCSV from "./Dashboard/CreateMultipleShipments/UploadCSV";
import ShippingHistory from "./Dashboard/ShippingHistory/ShippingHistory";
import ShippingHistoryDetails from "./Dashboard/ShippingHistory/ShippingHistoryDetails";
import TrackShipments from "./Dashboard/TrackShipments/TrackShipments";
import Chats from "./Dashboard/Chats/Chats";
import Invoice from "./Dashboard/Invoice/Invoice";
import AssignMasterNumber from "./Dashboard/MasterNumber/AssignMasterNumber";
import AssignFlightDetails from "./Dashboard/MasterNumber/AssignFlightDetails";
import ExternalTrackingList from "./Dashboard/ExternalTracking/ExternalTrackingList";
import EditExternalTrackingNumber from "./Dashboard/ExternalTracking/EditExternalTracking";
import UpdateStatusAirway from "./Dashboard/MasterNumber/UpdateStatus";
import DownloadManifest from "./Dashboard/MasterNumber/DownloadManifest";
import LookupAirway from "./Dashboard/MasterNumber/LookupAirway";
import InvoiceByAmount from "./Dashboard/Invoice/InvoiceByAmount";
import CreateShippingBill from "./Dashboard/Invoice/CreateShippingBill";
import AssignExternalTracking from "./Dashboard/ExternalTracking/AssignExternalTracking";
import Help from "./Dashboard/Help/Help";
import TrackShipmentsDetails from "./Dashboard/TrackShipments/TrackShipmentsDetails";
import TrackShipmentsDetailsIncoming from "./Dashboard/TrackShipments/TrackShipmentsDetailsIncoming";
import Profile from "./Dashboard/Profile/Profile"
import chatList from "./Dashboard/Chats/chatList";
import packageHandler from "./Dashboard/ShippingHistory/packageHandler";
import IncomingPackageHandler from "./Dashboard/TrackShipments/IncomingPackageHandler";
import OutGoingPackageHandler from "./Dashboard/TrackShipments/OutGoingPackageHandler";
import QRcode from "./Dashboard/QRcode/QRcode";
import Adhoc from "./Dashboard/Adhoc/AdhocLablePrint";
import AdhocMultiplePrint from "./Dashboard/Adhoc/AdhocMultiplePrint";
import ScanParcel from "./Dashboard/ScanParcel/ScanParcel";
//import ScanMultipleParcel from "./Dashboard/ScanMultipleParcels/ScanMultipleParcels";
import ScanMultipleParcel from "./Dashboard/ScanMultipleParcels/ScanMultipleParcelsUpdated";
import Setting from "./Dashboard/Setting/Setting";
import PriceList from "./Dashboard/PriceList/PriceList";
import ApiAccess from "./Dashboard/ApiAcess/ApiAcess";
import ScreenAccessRequests from './Dashboard/ScreenAccess/ScreenAccessRequests';
import GetApiAccess from './Dashboard/ApiAcess/GetApiAcess';
import CourierDash from './Dashboard/CourierDash/CourierDash';
import AllRiders from './Dashboard/CourierDash/AllRiders';
import GrantApiAcess from './Dashboard/ApiAcess/GrantAcess';
import CreateDomesticShipment from "./Dashboard/CreateShipment/CreateDomesticShipment";
import DownloadHistory from "./Dashboard/CreateMultipleShipments/DownloadHistory";
import UserActivity from "./Dashboard/UserActivity/UserActivity";
import AddCredits from "./Dashboard/UserManagement/AddCredits";
import ViewShipment from "./Dashboard/Shipment/ViewShipment";
import SendBulkMessages from "./Dashboard/UserManagement/SendBulkMessages";
import UnAssignExternalTracking from "./Dashboard/ExternalTracking/UnAssignExternalTracking";
import UnAssignExternalList from "./Dashboard/ExternalTracking/UnassignExternalList";
import MultipleExternal from "./Dashboard/ExternalTracking/MultipleExternal";
import MultipleExternalUnassign from "./Dashboard/ExternalTracking/MultipleExternalUnassign";
import UserSettings from "./Dashboard/UserManagement/UserSettings";
import PurchaseCredits from "./Dashboard/HomePage/PurchaseCredits";
import EditUnassignExternal from "./Dashboard/ExternalTracking/EditUnassignExternal";
import UpdateCredits from "./Dashboard/UserManagement/UpdateCreditUnits";
import PaypalHistory from "./Dashboard/HomePage/PaypalHistory";
import ApiHits from "./Dashboard/Reports/ApiHits";
import InvoiceReports from "./Dashboard/Reports/InvoiceReports";
import AdhocInvoice from "./Dashboard/AdhocInvoice/AdhocInvoice";
import AddTemplate from "./Dashboard/AdhocInvoice/AddTemplate";
import GenerateInvoices from "./Dashboard/AdhocInvoice/GenerateInvoices";
import EditTemplate from "./Dashboard/AdhocInvoice/EditTemplate";
import TrackShipmentHomePage from "./Dashboard/HomePage/TrackShipmentHomePage";
import DeleteShipment from "./Dashboard/DeleteShipment/DeleteShipment";
import DeleteShipmentAccessRequest from "./Dashboard/DeleteShipmentAcess/DeleteShipmentAccessRequest";
import AllUsers from "./Dashboard/Users/AllUsers";
import UpdateBanner from "./Dashboard/UpdateBanner/UpdateBanner";
import ShippingCalculator from "./components/ShippingCalculator";
import Header from "./Dashboard/Cipl/Header";
import CategoryList from "./Dashboard/Cipl/product-databse/CategoryList";
import ProductList from "./Dashboard/Cipl/product-databse/ProductList";
import CreateProductList from "./Dashboard/Cipl/product-databse/CreateProductList";

var routes = [
  {
    path: "/index",
    name: "Dashboard",
    icon: "fa fa-home",
    component: Index,
    layout: "/admin"
  },
  {
    path: "/scanParcel",
    name: "Scan Parcel",
    icon: "fa fa-qrcode",
    component: ScanParcel,
    layout: "/admin"
  },
  {
    path: "/trackShipmentsHome",
    name: "Track Shipment",
    icon: "fa fa-map-marker",
    component: ShippingHistoryDetails,
    layout: "/admin",
    hidden: true
  },
  {
    path: "/scanMultipleParcel",
    name: "Update Multiple Parcels",
    icon: "fa fa-qrcode",
    component: ScanMultipleParcel,
    layout: "/admin"
  },
  {
    path: "/createShipment",
    name: "Create Shipment",
    icon: "fa fa-truck",
    component: CreateShipment,
    layout: "/admin"
  },
  {
    path: "/editShipment",
    name: "Edit Shipment",
    icon: "fa fa-truck",
    component: Adhoc,
    layout: "/admin"
  },
  {
    path: "/createShipmentDomestic",
    name: "Create Shipment",
    icon: "fa fa-truck",
    component: CreateDomesticShipment,
    layout: "/admin",
    hidden: true
  },
  {
    path: "/createMultipleShipments",
    name: "Create Multiple Shipments",
    icon: "fa fa-truck",
    component: UploadCSV,
    layout: "/admin"
  },
  {
    path: '/bulkMessages',
    name: "Bulk Messages",
    icon: "fa fa-comments-o",
    component: SendBulkMessages,
    layout: "/admin"
  },
  {
    path: '/addCredits',
    name: "Add Credits",
    icon: "fa fa-money",
    component: AddCredits,
    layout: "/admin"
  },
  {
    path: '/users',
    name: "Users",
    icon: "fa fa-user",
    component: AllUsers,
    layout: "/admin"
  },
  {
    path: "/downloadHistory",
    name: "Download History",
    icon: "fa fa-download",
    component: DownloadHistory,
    layout: "/admin"
  },
  {
    path: "/shippingHistory",
    name: "Shipping History",
    icon: "fa fa-history",
    component: ShippingHistory,
    layout: "/admin"
  },
  {
    path: "/apiHits",
    name: "Api Hits",
    icon: "fa fa-file-text-o",
    component: ApiHits,
    layout: "/admin"
  },
  {
    path: "/invoiceReports",
    name: "Invoice Reports",
    icon: "fa fa-file-text-o",
    component: InvoiceReports,
    layout: "/admin"
  },
  // {
  //   path: "/apiHits",
  //   name: "MAWB Reports",
  //   icon: "fa fa-file-text-o",
  //   component: ApiHits,
  //   layout: "/admin"
  // },
  {
    path: "/purchaseCredits",
    name: "Purchase Credits",
    icon: "fa fa-history",
    component: PurchaseCredits,
    layout: "/admin"
  },
  {
    path: "/shippingHistoryDetails",
    name: "Shipping History Details",
    icon: "fa fa-history",
    component: ShippingHistoryDetails,
    layout: "/admin",
    hidden: true
  },
  {
    path: "/packageHandler",
    name: "packageHandler",
    icon: "fa fa-history",
    component: packageHandler,
    layout: "/admin",
    hidden: true
  },
  {
    path: "/trackShipments",
    name: "Track Shipments",
    icon: "fa fa-map-marker",
    component: TrackShipments,
    layout: "/admin"
  },
  {
    path: "/viewShipments",
    name: "View Shipments",
    icon: "fa fa-truck",
    component: ViewShipment,
    layout: "/admin"
  },
  {
    path: "/cipl",
    name: "CIPL",
    icon: "fa fa-calendar",
    child: [
      {
        path: "/cipl",
        name: "Create new CIPL",
        icon: "fa fa-calendar",
        component: Header,
        layout: "/admin"
      },
      {
        path: "/product-database",
        name: "Product database",
        icon: "fa fa-truck",
        component: CategoryList,
        layout: "/admin"
      },
      {
        path: "/headers",
        name: "Create Header",
        icon: "fa fa-truck",
        component: Header,
        layout: "/admin"
      },
    ],
    component: Header,
    layout: "/admin",
  },
  {
    path: "/cipl",
    name: "Create new CIPL",
    icon: "fa fa-truck",
    component: Header,
    layout: "/admin",
    hidden: true
  },
  {
    path: "/product-database",
    name: "Product database",
    icon: "fa fa-truck",
    component: CategoryList,
    layout: "/admin",
    hidden: true
  },
  {
    path: "/headers",
    name: "Create Header",
    icon: "fa fa-truck",
    component: Header,
    layout: "/admin",
    hidden: true
  },
  {
    path: "/product-list",
    name: "Product List",
    icon: "fa fa-truck",
    component: ProductList,
    layout: "/admin",
    hidden: true
  },
  {
    path: "/create-product-list",
    name: "Create Product List",
    icon: "fa fa-truck",
    component: CreateProductList,
    layout: "/admin",
    hidden: true
  },
  {
    path: "/trackShipmentsDetails",
    name: "Track Shipments",
    icon: "fa fa-map-marker",
    component: TrackShipmentsDetails,
    layout: "/admin",
    hidden: true
  },
  {
    path: "/incomingPackageHandler",
    name: "incomingPackageHandler",
    icon: "fa fa-map-marker",
    component: IncomingPackageHandler,
    layout: "/admin",
    hidden: true
  },
  {
    path: "/outGoingPackageHandler",
    name: "outGoingPackageHandler",
    icon: "fa fa-map-marker",
    component: OutGoingPackageHandler,
    layout: "/admin",
    hidden: true
  },
  {
    path: "/profile",
    name: "profile",
    icon: "fa fa-map-marker",
    component: Profile,
    layout: "/admin",
    hidden: true
  },
  {
    path: "/trackShipmentsDetailsIncoming",
    name: "Track Shipments",
    icon: "fa fa-map-marker",
    component: TrackShipmentsDetailsIncoming,
    layout: "/admin",
    hidden: true
  },
  {
    path: "/chats/",
    name: "Chats",
    icon: "fa fa-comments-o",
    component: Chats,
    layout: "/admin"
  },
  {
    path: "/chatPage/:id",
    name: "Chats",
    icon: "fa fa-comments-o",
    component: Chats,
    layout: "/admin",
    hidden: true,
  },
  {
    path: "/qrcode",
    name: "QR code",
    icon: "fa fa-qrcode",
    component: QRcode,
    layout: "/admin",
    hidden: true
  },
  {
    path: "/adhoc",
    name: "Print Label",
    icon: "fa fa-print",
    component: Adhoc,
    layout: "/admin"
  },
  {
    path: "/adhocMultiple",
    name: "Print Label",
    icon: "fa fa-print",
    component: AdhocMultiplePrint,
    layout: "/admin",
    hidden: true,
  },
  {
    path: "/assignExternalTracking",
    name: "External Tracking",
    icon: "fa fa-truck",
    child: [
      {
        path: "/assignExternalTracking",
        name: "Assign New",
        icon: "fa fa-truck",
        component: AssignExternalTracking,
        layout: "/admin",
      },
      {
        path: "/externalTrackingList",
        name: "External Tracking List",
        icon: "fa fa-truck",
        component: ExternalTrackingList,
        layout: "/admin"
      },
      {
        path: "/unassignExternalTracking",
        name: "Assign New(Unassigned)",
        icon: "fa fa-truck",
        component: UnAssignExternalTracking,
        layout: "/admin",
      },
      {
        path: "/externalTrackingListUnassign",
        name: "Unassign External Tracking",
        icon: "fa fa-truck",
        component: UnAssignExternalList,
        layout: "/admin"
      },


    ],
    component: AssignExternalTracking,
    layout: "/admin",
  },
  {
    path: "/assignExternalTracking",
    name: "Assign External Tracking Numbers",
    icon: "fa fa-truck",
    component: AssignExternalTracking,
    layout: "/admin",
    hidden: true,
  },
  {
    path: "/unassignExternalTracking",
    name: "Assign New(Unassigned)",
    icon: "fa fa-truck",
    component: UnAssignExternalTracking,
    layout: "/admin",
    hidden: true,
  },
  {
    path: "/multipleExternal",
    name: "Bulk Upload External",
    icon: "fa fa-truck",
    component: MultipleExternal,
    layout: "/admin",
    hidden: true,
  },
  {
    path: "/multipleExternalUnassign",
    name: "Bulk Upload External(Unassigned)",
    icon: "fa fa-truck",
    component: MultipleExternalUnassign,
    layout: "/admin",
    hidden: true
  },
  {
    path: "/externalTrackingListUnassign",
    name: "Unassign External Tracking",
    icon: "fa fa-truck",
    component: UnAssignExternalList,
    layout: "/admin",
    hidden: true,
  },
  {
    path: "/externalTrackingList",
    name: "External Tracking List",
    icon: "fa fa-truck",
    component: ExternalTrackingList,
    layout: "/admin",
    hidden: true
  },
  {
    path: '/editExternalTrackingNumber',
    name: "Edit External Tracking Number",
    icon: "fa fa-truck",
    component: EditExternalTrackingNumber,
    layout: "/admin",
    hidden: true,
  },
  {
    path: '/editUnassignExternal',
    name: 'Edit External Tracking Number',
    icon: 'fa fa-truck',
    component: EditUnassignExternal,
    layout: "/admin",
    hidden: true,
  },
  {
    path: "/assignMasterNumber",
    name: "Master Bill",
    icon: "fa fa-print",
    child: [
      {
        path: "/assignMasterNumber",
        name: "Assign Master Number",
        icon: "fa fa-file-text-o",
        component: AssignMasterNumber,
        layout: "/admin"
      },
      {
        path: "/assignFlightDetails",
        name: "Assign MOT Details",
        icon: "fa fa-file-text-o",
        component: AssignFlightDetails,
        layout: "/admin"
      },
      {
        path: "/downloadManifest",
        name: "Download Manifest",
        icon: "fa fa-file-text-o",
        component: DownloadManifest,
        layout: "/admin"
      },
      {
        path: "/updateStatus",
        name: "Update Status",
        icon: "fa fa-file-text-o",
        component: UpdateStatusAirway,
        layout: "/admin"
      },
      {
        path: "/lookupMasterNumber",
        name: "Lookup for Master Number",
        icon: "fa fa-file-text-o",
        component: LookupAirway,
        layout: "/admin"
      }
    ],
    component: AssignMasterNumber,
    layout: "/admin",
  },
  {
    path: "/assignMasterNumber",
    name: "Assign Master Number",
    icon: "fa fa-file-text-o",
    component: AssignMasterNumber,
    layout: "/admin",
    hidden: true
  },
  {
    path: "/assignFlightDetails",
    name: "Assign MOT Details",
    icon: "fa fa-file-text-o",
    component: AssignFlightDetails,
    layout: "/admin",
    hidden: true
  },
  {
    path: "/downloadManifest",
    name: "Download Manifest",
    icon: "fa fa-file-text-o",
    component: DownloadManifest,
    layout: "/admin",
    hidden: true
  },
  {
    path: "/updateStatus",
    name: "Update Status",
    icon: "fa fa-file-text-o",
    component: UpdateStatusAirway,
    layout: "/admin",
    hidden: true
  },
  {
    path: "/lookupMasterNumber",
    name: "Lookup for Master Number",
    icon: "fa fa-file-text-o",
    component: LookupAirway,
    layout: "/admin",
    hidden: true
  },
  {
    path: "/chatsList",
    name: "Chats",
    icon: "fa fa-comments-o",
    component: chatList,
    layout: "/admin",
    hidden: true
  },
  {
    path: "/invoice",
    name: "ESD",
    icon: "fa fa-file-text-o",
    component: Invoice,
    layout: "/admin"
  },
  {
    path: "/adhocinvoice",
    name: "Adhoc Invoice",
    icon: "fa fa-file-text-o",
    component: AdhocInvoice,
    layout: "/admin",
    // hidden: true,
  },
  {
    path: "/addtemplate",
    name: "Add Template",
    icon: "fa fa-file-text-o",
    component: AddTemplate,
    layout: "/admin",
    hidden: true,
  },
  {
    path: "/generateinvoice",
    name: "Generate Invoice",
    icon: "fa fa-file-text-o",
    component: GenerateInvoices,
    layout: "/admin",
    hidden: true,
  },
  {
    path: "/edittemplate",
    name: "Generate Invoice",
    icon: "fa fa-file-text-o",
    component: EditTemplate,
    layout: "/admin",
    hidden: true,
  },
  {
    path: "/invoiceByAmount",
    name: "Invoice",
    icon: "fa fa-file-text-o",
    component: InvoiceByAmount,
    layout: "/admin"
  },
  // create new route for create bill
  {
    path: "/createShippingBill",
    name: "Create Shipping Bill",
    icon: "fa fa-file-text-o",
    component: CreateShippingBill,
    layout: "/admin"
  },
  {
    path: "/allRiders",
    name: "All Riders",
    icon: "fa fa-file-text-o",
    component: AllRiders,
    layout: "/admin",
    hidden: true,
  },
  // {
  //   path: "/setting",
  //   name: "Settings",
  //   icon: "fa fa-cog",
  //   component: Setting,
  //   layout: "/admin"
  // },
  {
    path: "/help",
    name: "Help",
    icon: "fa fa-question",
    component: Help,
    layout: "/admin"
  },
  {
    path: '/userSettings',
    name: 'User Settings',
    icon: 'fa fa-cog',
    component: UserSettings,
    layout: "/admin",
    hidden: true
  },
  {
    path: "/pricelist",
    name: "Price configurable",
    icon: "fa fa-cog",
    component: PriceList,
    layout: "/admin",
  },
  {
    path: "/apiAccess",
    name: "API Requests",
    icon: "fa fa-cog",
    component: ApiAccess,
    layout: "/admin",
  },
  {
    path: "/accessRequests",
    name: "Access Requests",
    icon: "fa fa-cog",
    component: ScreenAccessRequests,
    layout: "/admin",
  },
  // {
  //   path: "/deleteShipmentAccessRequest",
  //   name: "Delete Shipment",
  //   icon: "fa fa-trash-o",
  //   component: DeleteShipmentAccessRequest,
  //   layout: "/admin"
  // },
  {
    path: "/shipmentAccess",
    name: "Bin",
    icon: "fa fa-recycle",
    component: DeleteShipment,
    layout: "/admin",
    // hidden: false
  },
  {
    path: "/grantApiAcess/:id",
    name: "API Requests",
    icon: "fa fa-cog",
    component: GrantApiAcess,
    layout: "/admin",
    hidden: true
  },
  {
    path: "/userActivity",
    name: "User Activity",
    icon: "fa fa-history",
    component: UserActivity,
    layout: "/admin",
  },
  {
    path: "/updateCredits",
    name: "Update Credits Units",
    icon: "fa fa-cog",
    component: UpdateCredits,
    layout: "/admin"
  },
  {
    path: "/purchaseHistory",
    name: "Purchase History",
    icon: "fa fa-history",
    component: PaypalHistory,
    layout: "/admin",
    hidden: true,
  },
  {
    path: "/update-banner",
    name: "Update Banner Slides",
    icon: "fa fa-edit",
    component: UpdateBanner,
    layout: "/admin",
  },
  {
    path: '/shipping-calculator',
    name: 'Shipping Calculator',
    icon: 'fa fa-calculator',
    component: ShippingCalculator,
    layout: "/admin",
  },


];
export default routes;
