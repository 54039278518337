import React, { Component } from 'react';
import { Button, Card, CardBody, CardHeader } from 'reactstrap';

class CreateProductList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            categoryName: '',
            products: [{ name: '', hsCode: '' }],
        };
    }

    handleAddItem = () => {
        this.setState({
            products: [...this.state.products, { name: '', hsCode: '' }],
        });
    };

    handleInputChange = (index, field, value) => {
        const products = [...this.state.products];
        products[index][field] = value;
        this.setState({ products });
    };

    render() {
        return (
            <div className="w-100 px-0 py-2">
                <Card>
                    <CardHeader className="c-header">Product Database</CardHeader>
                    <CardBody>
                        <h4 className="mt-3">Create Products List</h4>
                        <form>
                            <div className="mb-3">
                                <label>Category Name</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    value={this.state.categoryName}
                                    onChange={(e) => this.setState({ categoryName: e.target.value })}
                                />
                            </div>
                            {this.state.products.map((product, index) => (
                                <div className="row mb-3" key={"product-" + product}>
                                    <div className="col-md-6">
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="products Name"
                                            value={product.name}
                                            onChange={(e) => this.handleInputChange(index, 'name', e.target.value)}
                                        />
                                    </div>
                                    <div className="col-md-4">
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="HS Code"
                                            value={product.hsCode}
                                            onChange={(e) => this.handleInputChange(index, 'hsCode', e.target.value)}
                                        />
                                    </div>
                                    <div className="col-md-2">
                                        <button
                                            type="button"
                                            className="btn btn-danger"
                                            onClick={() => this.handleRemoveItem(index)}
                                        >
                                            Remove
                                        </button>
                                    </div>
                                </div>
                            ))}
                            <div>
                                <button
                                    type="button"
                                    className="btn btn-secondary"
                                    onClick={this.handleAddItem}
                                >
                                    + Add Item
                                </button>
                                <div className="d-flex justify-content-end w-100 my-3">


                                    <div className='ms-auto text-end mr-3'>
                                        <Button style={{ whiteSpace: "nowrap" }} color={"primary"} onClick={() => { }}>Save Global</Button>
                                    </div>
                                    <div className='ms-auto text-end mr-3'>
                                        <Button style={{ whiteSpace: "nowrap" }} color={"primary"} onClick={() => { }}>Save </Button>
                                    </div>

                                </div>
                            </div>
                        </form>
                    </CardBody>
                </Card>
            </div>
        );
    }
}

export default CreateProductList;
