import React, { Component } from 'react';
import { Button, Card, CardBody, CardHeader } from 'reactstrap';

class ProductList extends Component {
    render() {
        const products = [
            { name: 'Product Name 1', hsCode: '1234567890' },
            { name: 'Product Name 2', hsCode: '1234567890' },
            { name: 'Product Name 3', hsCode: '1234567890' },
        ];

        return (
            <div className='w-100 px-0 py-2'>
                <Card>
                    <CardHeader className="c-header">Product Database</CardHeader>
                    <CardBody>
                        <div className="d-flex justify-content-between w-100 my-3">
                            <h4 className="ml-3">Product List</h4>
                            <div className='ms-auto text-end mr-3'>
                                <Button style={{ whiteSpace: "nowrap" }} color={"primary"} onClick={() => window.location.href = ""}> Edit Product List</Button>
                            </div>
                        </div>
                        <table className="table table-bordered">
                            <thead>
                                <tr>
                                    <th>Product Name</th>
                                    <th>HS Code/Tariff No</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {products.map((product) => (
                                    <tr key={"product-" + product}>
                                        <td>{product.name}</td>
                                        <td>{product.hsCode}</td>
                                        <td>
                                            <button className='py-1 px-2 bg-danger rounded'>
                                                <i className="fa fa-trash" style={{ fontSize: "20px", color: "white" }}></i>
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </CardBody>
                </Card>
            </div>
        );
    }
}

export default ProductList;
